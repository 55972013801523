import axios, { AxiosInstance, AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { APIResponse } from './APIResponse';
import { isEmptyString, isNullOrUndefined, getCookie } from '../../utils';

const defaultBaseUrl = process.env.REACT_APP_API_URL;

export class BaseAPI {
  _axios: AxiosInstance;

  constructor(baseURL?: string, token?: string, passCookies?: boolean, addParams?: Record<string, string> | null) {
    const axiosInitData: AxiosRequestConfig = {};
    //axiosInitData.baseURL = isEmptyString(baseURL) ? defaultBaseUrl : baseURL;
    axiosInitData.withCredentials = isNullOrUndefined(passCookies) ? true : passCookies;

    if (!isEmptyString(token)) {
      axiosInitData.headers = {
        Authorization: token,
      };
    }

    if (!isNullOrUndefined(addParams)) {
      if (!axiosInitData.headers) {
        axiosInitData.headers = (addParams === null ? undefined : addParams);
      } else {
        Object.assign(axiosInitData.headers, addParams);
      }
    }

    if (Object.keys(axiosInitData).length === 0 && axiosInitData.constructor === Object) {
      this._axios = axios.create();
    } else {
      this._axios = axios.create(axiosInitData);
    }
  }

  getDefaultBaseUrl = () => {
    return defaultBaseUrl;
  };

  private tokenHeader = () => {
    const token = getCookie("token");
    const header: {[k: string]: any} = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    return header;
  }

  async get(url: string): Promise<APIResponse<any>> {
    console.log(url);
    try {
      const res = await this._axios.get(url, this.tokenHeader());
      return this.apiResponse(true, res);
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  async post(url: string, data: any): Promise<APIResponse<any>> {
    console.log(url);
    try {
      const res = await this._axios.post(url, data, this.tokenHeader());
      return this.apiResponse(true, res);
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  async postMultipart(url: string, data: any, files: File[]): Promise<APIResponse<any>> {
    console.log(url);
    try {
      const formData = new FormData();
      formData.append('animal', JSON.stringify(data));
      files.forEach((file) => {
        formData.append('files', file);
      });

      let config = this.tokenHeader();
      config.headers["Content-Type"] = "multipart/form-data";

      const res = await this._axios.post(url, formData, config);
      return this.apiResponse(true, res);
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  async put(url: string, data: any): Promise<APIResponse<any>> {
    try {
      const res = await this._axios.put(url, data, this.tokenHeader());
      return this.apiResponse(true, res);
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  async patch(url: string, data: any): Promise<APIResponse<any>> {
    try {
      const res = await this._axios.patch(url, data, this.tokenHeader());
      return this.apiResponse(true, res);
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  async delete(url: string): Promise<APIResponse<any>> {
    try {
      const res = await this._axios.delete(url, this.tokenHeader());
      return this.apiResponse(true, res);
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  private apiResponse(success: boolean, res: AxiosResponse): APIResponse<any> {
    return {
      success: success,
      data: success ? res.data : res,
    };
  }

  private handleError(error: AxiosError): APIResponse<any> {
    return {
      success: false,
      data: error,
    };
  }
}