import { useState } from "react";
import { FileUpload } from "..";

interface IAnimalFilesSelectionProps {
  selectedFiles?: File[];
  onFilesSelected: (files: File[]) => void;
}

export const AnimalFilesSelection = (props: IAnimalFilesSelectionProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>(props.selectedFiles ?? []);

  const onFileSelectionChangeHandler = (files: File[]) => {
    setSelectedFiles(files);
    props.onFilesSelected(files);
  };

  return (
    <FileUpload
      maxFilesCount={4}
      maxFilesSizeInMB={15}
      selectedFiles={selectedFiles}
      filesTypeAccept={["image/*"]} // "application/pdf" not available yet
      onFilesSelected={onFileSelectionChangeHandler}
    />
  );
}
