import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Input } from "../../components";
import { AuthService } from "../../services";
import { isNullOrUndefined } from "../../utils";
import styles from "./Login.module.css";

interface LoginForm {
  email: string;
  password: string;
}

interface LoginProps {
  onLogin: (isLogged: boolean) => void;
}

export const Login = (props : LoginProps, {authService = new AuthService()}) => {
  const { t } = useTranslation();
  const [form, setForm] = useState<LoginForm>({
    email: "",
    password: ""
  });
  const [error, setError] = useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const response = await authService.login(form.email, form.password);
    const success = response.success && !isNullOrUndefined(response.data) && (response.data as boolean);
    if (success) {
      props.onLogin(success);
    } else {
      setError(true);
    }
  };

  return (
    <div className={styles["login"]}>
      <h2>{t("login")}</h2>
      <form onSubmit={handleSubmit} className={styles["loginForm"]}>
        <div>
          <Input type="email" name="email" placeholder={t("email").toString()} value={form.email} onChange={handleInputChange} />
        </div>
        <div>
          <Input type="password" name="password" placeholder={t("password").toString()} value={form.password} onChange={handleInputChange} />
        </div>
        <div  className={styles["loginButton"]}>
          <Button type="submit">{t("login")}</Button>
        </div>
        {error && <div>{t("loginInvalidData")}</div>}
      </form>
      <p>
        <Link to="/forgotpassword">{t("forgotPassword")}</Link>
      </p>
      <p>
        {t("dontHaveAnAccount")} <Link to="/register">{t("register")}</Link>
      </p>
    </div>
  );
};
