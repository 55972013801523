import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button, Input } from '../../components';
import { isEmptyString } from "../../utils";
import { AuthService } from '../../services';

import styles from "./ResetPassword.module.css";


interface ResetPasswordProps {
  onResetPassword: () => void;
}

export const ResetPassword = (props: ResetPasswordProps, {authService = new AuthService()}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [resetPasswordError, setResetPasswordError] = useState<boolean>(false);
  const [usedOrExpiredCodeError, setUsedOrExpiredCodeError] = useState<boolean>(false);

  const code = searchParams.get("code") ?? "";
  
  const onPasswordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNewPassword(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const response = await authService.resetPassword(newPassword, code ?? "");

    if (response.success && response.data) {
      props.onResetPassword();
    } else {
      setResetPasswordError(true);
    }
  };

  const validateCode = async () => {
    const response = await authService.validateExternalCode(code);
    const isValid = response.success && response.data;
    if (!isValid) {
      setUsedOrExpiredCodeError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    validateCode();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  return (
    <div className={styles["resetPassword"]}>
      {loading && <p>{t("loading")}</p>}
      {usedOrExpiredCodeError && <p>{t("usedOrExpiredExternalCodeError")}</p>}
      {!loading && !usedOrExpiredCodeError && <>
      <h2>{t("resetPasswordTitle")}</h2>
      <p>{t("resetPasswordInfo")}</p>
      <form onSubmit={handleSubmit} className={styles["resetPasswordForm"]}>
        <div>
          <Input type="password" name="newPassword" placeholder={t("password").toString()} value={newPassword} onChange={onPasswordChangeHandler} />
        </div>
        <div  className={styles["resetPasswordButton"]}>
          <Button type="submit" disabled={isEmptyString(newPassword)}>{t("resetPasswordSubmitButton")}</Button>
        </div>
        {resetPasswordError && <p>{t("resetPasswordError")}</p>}
      </form>
      </>}
    </div>
  );
}
