import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "..";
import { isNullOrUndefined } from "../../utils";
import uploadFileIcon from "../../assets/UploadFile.svg";
import pdfFileIcon from "../../assets/PdfFile.svg";
import binIcon from "../../assets/Bin.svg";
import styles from "./FileUpload.module.css";

type fileAccept = "*" | "image/png" | "image/jpg" | "image/jpeg" | "image/*" | "application/pdf"

interface IFileUploadProps {
  filesTypeAccept?: fileAccept[];
  maxFilesSizeInMB?: number;
  maxFilesCount?: number;
  selectedFiles?: File[];
  onFilesSelected: (files: File[]) => void;
}

export const FileUpload = (props: IFileUploadProps) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<File[]>(props.selectedFiles ?? []);
  const [errorMessage, setErrorMessage] = useState<string>();
  const filesTypeAccept = props.filesTypeAccept ? props.filesTypeAccept.map(a => a.toString()).join(",") : "*";
  const selectMultiple = props.maxFilesCount !== 1;

  const onUploadFileClickHandler = () => {
    const input = document.getElementById("fileInput");
    input?.click();
  };

  const onFileSelectionChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      // Convert FileList to Array and filter for only image and PDF files that are not already added
      const newFiles = Array.from(event.target.files).filter(file =>
        (file.type.startsWith("image/") || file.type.startsWith("application/pdf")) &&
        isNullOrUndefined(selectedFiles.find(sf => sf.name === file.name))
      );

      let filesCount = selectedFiles.length;
      let filesSizeInMB = 0;
      selectedFiles.forEach(file => {
        filesSizeInMB += file.size / (1024 * 1024);
      })
      newFiles.forEach(file => {
        filesSizeInMB += file.size / (1024 * 1024);
        filesCount += 1;
      })

      if (props.maxFilesCount && selectMultiple && filesCount > props.maxFilesCount) {
        // event.target.value = "";
        // setSelectedFiles([]);
        const msg = t("filesMaxCountError", { count: props.maxFilesCount });
        setErrorMessage(msg);
      }
      else if (props.maxFilesSizeInMB && filesSizeInMB > props.maxFilesSizeInMB) {
        const msg = t("filesMaxSizeError", { sizeInMB: props.maxFilesSizeInMB });
        setErrorMessage(msg);
      }
      else {
        setErrorMessage(undefined);
        if (selectMultiple) {
          const temp = [...selectedFiles, ...newFiles];
          setSelectedFiles(temp);
          props.onFilesSelected(temp);
        }
        else {
          setSelectedFiles(newFiles);
          props.onFilesSelected(newFiles);
        }
      }
    }
  };

  const onDeleteFileHandler = (filename: string) => {
    const temp = selectedFiles.filter(f => f.name !== filename);
    setSelectedFiles(temp);
    props.onFilesSelected(temp);
  };

  const getFileIconSource = (file: File) : string => {
    if (file.type.startsWith('image/')) {
      return URL.createObjectURL(file);
    }
    else if (file.type.startsWith('application/pdf')) {
      return pdfFileIcon;
    }
    else {
      return pdfFileIcon;  // To-Do: replace with a generic file icon
    }
  };

  const getFormattedFilename = (filename: string) : string => {
    const maxLength = 20;
    const splitLength = Math.floor((maxLength / 2) - 1);
    return filename.length <= maxLength ?
            filename :
            `${filename.substring(0, splitLength)}...${filename.substring(filename.length-splitLength, filename.length)}`;
  }

  return (
    <>
      <input id="fileInput" name="file" type="file" accept={filesTypeAccept} multiple={selectMultiple} hidden onChange={onFileSelectionChangeHandler} />
      <Button secondary onClick={onUploadFileClickHandler}>
        <>
          {t("uploadFiles")}
          <img alt="" src={uploadFileIcon} className={styles["uploadFileIcon"]} />
        </>
      </Button>
      {errorMessage && <p>{errorMessage}</p>}
      <div className={styles["filesPreview"]} style={{flex: 'flex-start'}}>
        {selectedFiles.map((file, index) => {
          return (
            <div key={index} className={styles["previewFile"]}>
              <div className={styles["previewIcon"]}>
                <img src={getFileIconSource(file)} alt={`${file.name} preview`} />
              </div>
              <div className={styles["previewFilename"]}>
                {getFormattedFilename(file.name)}
              </div>
              <div className={styles["previewDelete"]}>
                <img src={binIcon} alt={`Delete file`} onClick={() => onDeleteFileHandler(file.name)} />
              </div>
            </div>)
        })}
      </div>
    </>
  );
}
