import { APIResponse, BaseAPI } from "./api";

export class DiagnosesService {
  private api: BaseAPI;

  private diagnosesUrl = "api/diagnoses";

  constructor() {
    this.api = new BaseAPI();
  }

  async makeDiagnosis(clinicalCase: ClinicalCase): Promise<APIResponse<DiagnosisResponse>> {
    var url = this.diagnosesUrl + "/make";
    var data = {
      specie: clinicalCase.specie,
      breed: clinicalCase.breed,
      gender: clinicalCase.gender,
      sterilized: clinicalCase.sterilized,
      ageRange: clinicalCase.age,
      weight: clinicalCase.weight,
      symptoms: clinicalCase.symptoms,
      medicalHistory: clinicalCase.medicalHistory,
      vaccines: clinicalCase.vaccines
    };

    const response = await this.api.post(url, data);
    return response;
  }

  async makeDiagnosisWithFiles(clinicalCase: ClinicalCase): Promise<APIResponse<DiagnosisResponse>> {
    var url = this.diagnosesUrl + "/makeWithFiles";
    var data = {
      specie: clinicalCase.specie,
      breed: clinicalCase.breed,
      gender: clinicalCase.gender,
      sterilized: clinicalCase.sterilized,
      ageRange: clinicalCase.age,
      weight: clinicalCase.weight,
      symptoms: clinicalCase.symptoms,
      medicalHistory: clinicalCase.medicalHistory,
      vaccines: clinicalCase.vaccines
    };

    const response = await this.api.postMultipart(url, data, clinicalCase.files);
    return response;
  }
  
  async sendFeedback(diagnosticGuid: string, score: number, message: string): Promise<void> {
    var url = this.diagnosesUrl + "/feedback";
    var data = {
      guid: diagnosticGuid,
      score: score,
      feedback: message
    };

    await this.api.patch(url, data);
  }
}


export class ClinicalCase {
  public specie: string;
  public breed: string;
  public gender: string;
  public sterilized: boolean;
  public age: string;
  public weight: number;
  public symptoms: string;
  public medicalHistory: string;
  public vaccines: string[];
  public files: File[];

  constructor(
    specie: string,
    breed: string,
    gender: string,
    sterilized: boolean,
    age: string,
    weight: number,
    symptoms: string,
    medicalHistory: string,
    vaccines: string[],
    files: File[])
  {
    this.specie = specie;
    this.breed = breed;
    this.gender = gender;
    this.sterilized = sterilized;
    this.age = age;
    this.weight = weight;
    this.symptoms = symptoms;
    this.medicalHistory = medicalHistory;
    this.vaccines = vaccines;
    this.files = files;
  }
}


export class DiagnosisResponse {
  public guid: string;
  public result: string;

  constructor(
    guid: string,
    result: string)
  {
    this.guid = guid;
    this.result = result;
  }
}
